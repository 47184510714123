const icons = {
  copy: (
    <>
      <rect x='9' y='9' width='13' height='13' rx='2' ry='2'></rect>
      <path d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'></path>
    </>
  ),
  'log-out': (
    <>
      <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
      <polyline points='16 17 21 12 16 7'></polyline>
      <line x1='21' y1='12' x2='9' y2='12'></line>
    </>
  ),
  transfer: (
    <>
      <path d='M6.16237 9.39275H23.3932' />
      <path d='M19.624 13.162L23.3932 9.39277L19.624 5.62354' />
      <path d='M21.2393 18.008H4.0085' />
      <path d='M7.77777 14.2393L4.00853 18.0085L7.77777 21.7777' />
    </>
  ),
  globe: (
    <>
      <circle cx='12' cy='12' r='10'></circle>
      <line x1='2' y1='12' x2='22' y2='12'></line>
      <path d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z'></path>
    </>
  ),
  'chevron-down': <polyline points='6 9 12 15 18 9'></polyline>
}

interface Props {
  icon: keyof typeof icons;
  size?: number;
  color?: string;
}

export default function Icon ({ size = 24, color = '#fff', icon }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      {icons[icon]}
    </svg>
  )
}
