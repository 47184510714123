import { BrowserWallet } from '@meshsdk/core'
import type { Wallet } from '@meshsdk/core'
import { useEffect, useState } from 'react'

export const useAvailableWallets = () => {
  const [wallets, setWallets] = useState<Wallet[]>([])

  useEffect(() => {
    const installed = BrowserWallet.getInstalledWallets()
    setWallets(installed)
  }, [])

  return wallets
}
