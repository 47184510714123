import { baseURL } from 'config/app.config'
import style from './style.module.css'
import { supportedWallets } from 'config/wallets.config'
import { useWallet } from 'contexts/wallet.context'
import { useAvailableWallets } from 'hooks/useAvailableWallets'
import Icon from 'icons/Icon'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { trimString } from 'utils/trim-string'

export default function ConnectDropdown () {
  const wallets = useAvailableWallets()
  const { connect, disconnect, connected, wallet, name, connecting } =
    useWallet()
  const [address, setAddress] = useState('Loading...')
  const [open, setOpen] = useState(false)
  const router = useRouter()

  const getWalletAddress = async () => {
    if (!connected) return

    const addresses = await wallet.getRewardAddresses()
    if (addresses.length > 0) {
      setAddress(addresses[0])
    }
  }

  useEffect(() => {
    if (!connecting) {
      getWalletAddress()
    }

    if (connecting) {
      setAddress('Loading...')
    }
  }, [name, connected, connecting])

  return (
    <div
      className={style.dropdown}
      onClick={() => {
        setOpen((prev) => !prev)
      }}
      onPointerEnter={() => {
        setOpen(true)
      }}
      onPointerLeave={() => {
        setOpen(false)
      }}
    >
      <div className={style.dropdownTitleContainer}>
        <p className={style.dropdownTitle}>
          {connecting
            ? 'Connecting'
            : connected
              ? trimString(address, 16)
              : 'Connect wallet'}
        </p>
      </div>
      <div className={`${style.dropdownContainer} ${open ? style.focus : ''}`}>
        <div className={style.dropdownContent}>
          {!connected &&
            Object.keys(supportedWallets).map((wallet) => {
              const supportedWallet =
                supportedWallets[wallet as keyof typeof supportedWallets]
              const isInstalled =
                wallets.findIndex(
                  (installedWallet) =>
                    installedWallet.name === supportedWallet.name
                ) > -1

              return (
                <button
                  disabled={!isInstalled || name === supportedWallet.name}
                  key={supportedWallet.name}
                  onClick={() => {
                    connect(supportedWallet.name)
                  }}
                >
                  <div>
                    <Image
                      src={supportedWallet.icon}
                      alt={`${supportedWallet.name} icon`}
                      width={16}
                      height={16}
                    />
                  </div>
                  <p>{supportedWallet.name}</p>
                  {name === supportedWallet.name && (
                    <div className={style.connected}>
                      <div />
                    </div>
                  )}
                </button>
              )
            })}

          {connected && (
            <>
              <button
                onClick={(event) => {
                  navigator.clipboard.writeText(address)
                  toast.success('Address copied to clipboard')
                  event.stopPropagation()
                }}
              >
                <Icon icon='copy' size={16} color='#000' />
                <p>copy address</p>
              </button>
              <button
                onClick={(event) => {
                  disconnect()
                  router.push('https://spqc.io')
                  event.stopPropagation()
                }}
              >
                <Icon icon='log-out' size={16} color='#000' />
                <p>disconnect</p>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
